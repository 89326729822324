import React from 'react'
// Project Images

// import CBE from '../assets/CBEForVanAlbert.jpg'
import ProjectsImgCBE from '../assets/projects-img-cbe.jpg'
// import LPL from '../assets/LPLForVanalbert.jpg'
import ProjectsImgLPL from '../assets/projects-img-lpl.jpg'
import ProjectsImgHtmlSidecar from '../assets/projects-img-htmlsidecar.gif'
import ProjectsImgPatentDrawing from '../assets/projects-img-patent-drawing.jpg'
import ProjectsImgFusion from '../assets/projects-img-fusion.gif'
import ProjectsImgBuild from '../assets/projects-img-placeholder.jpg'
import ProjectsImgK75 from '../assets/projects-img-k75.jpg'
import ProjectsImgAnimation from '../assets/projects-img-animation.gif'
import ProjectsImgFaves from '../assets/projects-img-faves.png'
import ProjectsImgRenovation from '../assets/projects-img-renovation.jpg'
import ProjectsImgSidecarDog from '../assets/projects-img-sidecardog.jpg'



// Project Icons
import ProjectsIconCrossAndHigh from '../assets/projects-icon-cross-and-high.png'
import ProjectsIconLPL from  '../assets/projects-icon-lpl.png'
import ProjectsIconSidecar from '../assets/projects-icon-sidecar.png'
import ProjectsIconPatentPending from '../assets/projects-icon-patent-pending.png'
import ProjectsIconFusion from '../assets/projects-icon-fusion.png'
import ProjectsIconSolidworks from '../assets/projects-icon-solidworks.png'
import ProjectsIconCinema4d from '../assets/projects-icon-cinema4d.png'
import ProjectsIconK75 from '../assets/projects-icon-k75.png'
import ProjectsIconReact from '../assets/projects-icon-react.png'
import ProjectsIconRenovation from '../assets/projects-icon-renovation.png'
import ProjectsIconSidecarDog from '../assets/headeravatar-round.png'
// import FavesScreenshot from '../assets/faves-screenshot.png'
// import LearningIcon from '../assets/icon-learning.png'
// import AnimalNumbers from '../assets/animal-numbers.jpg'
// import CrossAndHighBand from '../assets/CrossAndHighVanAlbert.png'

/*
0 CBE
1 LPL
2 HTMLSidecar
3 Patent
4 Fusion
5 Solidworks
6 K75
7 C4D
8 Renovation
9 Faves
10 Sidecar Dog in NY
*/






// !VA Pass the project.json data as props from Projects component
const ProjectContent = ({ projects }) => {
  // !VA http://appft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&co1=AND&d=PG01&s1=%22481,735%22.APN.&OS=APN/%22481,735%22&RS=APN/%22481,735%22

  // !VA Assign the project images to arrays which are accessed in renderedItems via the index property in projects.json.
  const projects_images = [
    ProjectsImgCBE,
    ProjectsImgLPL,
    ProjectsImgHtmlSidecar,
    ProjectsImgPatentDrawing,
    ProjectsImgFusion,
    ProjectsImgBuild,
    ProjectsImgK75,
    ProjectsImgAnimation,
    ProjectsImgRenovation,
    ProjectsImgFaves,
    ProjectsImgSidecarDog
  ]
  const projects_icons = [
    ProjectsIconCrossAndHigh,
    ProjectsIconLPL,
    ProjectsIconSidecar,
    ProjectsIconPatentPending,
    ProjectsIconFusion,
    ProjectsIconSolidworks,
    ProjectsIconK75,
    ProjectsIconCinema4d,
    ProjectsIconRenovation,
    ProjectsIconReact,
    ProjectsIconSidecarDog
  ] 
  console.log('projects_images :>> ')
  console.log(projects_images)

  // !VA Map over the projects JSON data to produce the render output
  const renderedItems = projects.map((project) => {
    // !VA Destructure out the projects.json properties.
    const {
      index,
      type,
      headline,
      headertext,
      headersubtext,
      about,
      description1,
      description2,
      skills,
    } = project
    // !VA Create project-specific image and icon classes for selecting image and icon img elements.
    const img_classname = `project__image-${index}`
    const icon_classname = `project__icon-${index}`
    // !VA Use the projects.json index property as render key
    return (
      <React.Fragment key={index}>
        <div className={'segment project project-' + index}>
          <div className='project__header'>
            <div className='project__header-type'>
              <h4>{type}</h4>
            </div>
            <div className='project__header-content'>
              <div className='project__header-content-heading'>
                <h2
                  className='project__header-content-headline'
                  dangerouslySetInnerHTML={{ __html: headline }}></h2>
                <div className='project__header-content-icon'>
                  <img
                    src={projects_icons[index]}
                    className={icon_classname}
                    alt={project.headline}
                  />
                </div>
                <div className='project__header-content-subhead'>
                  <h3
                    className='project__header-content-subhead-text'
                    dangerouslySetInnerHTML={{ __html: headertext }}></h3>
                  <h3
                    className='project__header-content-subhead-subtext'
                    dangerouslySetInnerHTML={{ __html: headersubtext }}></h3>
                </div>
              </div>

              {/* <div className="project__header-content-text"> */}

              {/* </div> */}
            </div>
          </div>
          <div className='project__content'>
            <div className={'project__content-image ' + img_classname}>
              <img
                src={projects_images[index]}
                className={img_classname}
                alt={headline}
              />
              {/* <img src={projects_images[index]} className={img_classname} alt="" /> */}
            </div>
            <h3 className='project__content-heading project__content-content project__content-about'>
              {about}
            </h3>
            <p
              className='project__content-text project__content-description-1 project__content-description'
              dangerouslySetInnerHTML={{ __html: description1 }}></p>
            <p
              className='project__content-description-2 project__content-text project__content-description'
              dangerouslySetInnerHTML={{ __html: description2 }}></p>
            <p className='project__content-text project__content-skills'>
              {skills}
            </p>
          </div>
          <div className='project__separator'>
            <div className='separator'>&nbsp;</div>
          </div>
        </div>
      </React.Fragment>
    )
  })

  return (
    <>
      {renderedItems}
      <div className='project-separator'></div>
    </>
  )
}

export default ProjectContent
