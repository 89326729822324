import React from 'react';
import WorkstationContent from './WorkstationContent';



const Workstation = () => {
  
  return (
    <>
    <div className="page__content">
      <div className="content-banner">
        <div className="content-banner-content">
          <ul className="content-banner-content-list">
            <li>Workstation</li>
            {/* <li><span className="lg-bullet"></span></li>
            <li>Projects</li>
            <li> <span className="lg-bullet"></span></li>
            <li>Pursuits</li> */}
          </ul>
        </div>
      </div>
      <WorkstationContent />
    </div>
    </>
  );
};

export default Workstation;