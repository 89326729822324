import React from 'react';
// import Accordion from '../components/Accordion';
// import MBEnfjImage from '../assets/mb-enfj-head.png'
// import MBIntjImage from '../assets/mb-intj-head.png'
// import MBEnfiImage from '../assets/mb-enfi-head.png'
// import EnfjImg from '../assets/mb-enfj-icon.png';
// import IntjImg from '../assets/mb-intj-icon.png';
// import EntjImg from '../assets/mb-entj-icon.png';
// import TShapedImg from '../assets/t-shaped.png';
import WorkstationBuild from '../assets/workstation-image.jpg';
import WorkstationDevices from '../assets/workstation-devices.jpg';
import { Link } from 'react-router-dom';



const About = () => {
  return (
    <>
    <div className="page__content page__content-about">

      <div className="content-banner">
        <div className="content-banner-content">
          <ul className="content-banner-content-list">
            <li>About</li>
            {/* <li><span className="lg-bullet"></span></li>
            <li>Projects</li>
            <li> <span className="lg-bullet"></span></li>
            <li>Pursuits</li> */}
          </ul>
        </div>
      </div>

      <div className="segment about">
        <div className="about__content">




          <div className="about__content-intro">
            <h2 className="about__content-heading">Who is this person?</h2>
            <p className="about__content-text">For simplicity's sake, I'll refer this question to the Myers-Briggs Type Indicator (MBTI) analysis at <a href="https://www.truity.com">truity.com</a>, which describes me as a hybrid of three overlapping personality types:</p>


            <div className="about__content-types">
              <div className="about__content-type about__content-type-enfj">
                <div className="about__content-type-text">
                  <h4 className="about__content-types-caption">ENTJ&nbsp;&mdash;&nbsp;Commander</h4>
                  <p className="about__content-types-description">
                  Strategic leader, motivated to organize change. Quick to see inefficiency and conceptualize new solutions. Excels at logical reasoning and is articulate and quick-witted.
                  </p>
                </div>
              </div>
              <div className="about__content-type about__content-type-entj">
                <div className="about__content-type-text">
                  <h4 className="about__content-types-caption">INTJ&nbsp;&mdash;&nbsp;Architect</h4>
                  <p className="about__content-types-description">
                  Analytical problem-solver, eager to improve systems and processes with innovative ideas. Has a talent for seeing possibilities for improvement in work, home and personal environments.
                  </p>
                </div>
              </div>                  
                <div className="about__content-type about__content-type-enfj">
                  <div className="about__content-type-text">
                    <h4 className="about__content-types-caption">ENFJ&nbsp;&mdash;&nbsp;Teacher</h4>
                    <p className="about__content-types-description">
                    Driven by a deep sense of altruism and empathy for other people. Tends to personally experience the feelings of others, and feels compelled to act when confronted with people suffering. 
                    </p>
                  </div>
                </div> 
              </div>
              <p className="about__content-text">In reality, MBTI is widely critiqued as <a href="https://www.psychologytoday.com/intl/blog/give-and-take/201309/goodbye-mbti-the-fad-won-t-die?page=2">lacking scientific credibility</a>, so those descriptions are probably best taken with a large grain of salt.</p>
            </div>

          </div>

          <div className="about__content-motivate">

          <h2 className="about__content-heading">
              What motivates this person?
          </h2>
          {/* rewrite this and get rid of the learnaholic thing */}
          <p className="about__content-text">The most important activity in life for me is learning. I believe learning is a skill that has to be practiced on a never-ending basis, otherwise you forget how to do it. The more you practice it, the better at it you get.</p>
          <p className="about__content-text">
          I acquire new skills to achieve a specific objective. Through diverse objectives, I've accumulated a multi-dimensional set of skills geared towards content production and user education. This puts me in the category of generalizing specialists, or <a href="https://en.wikipedia.org/wiki/T-shaped_skills" target="blank">'T-shaped' people</a>, who are widely viewed as innovators and creative problem-solvers. </p>

          <h2 className="about__content-heading">
              How does this person think?
          </h2>
          <p className="about__content-text">The way I think was shaped by my 15-year career localizing US English user education content into German and implementing technology to improve consistency and productivity. </p>

          {/* <div className="about__content-tshaped" style={{ backgroundImage: `url(${TShapedImg})` }}> */}
              <p className="about__content-text">As a language professional, I've translated thousands of pages of technical documentation into German. Translators need to internalize the source content in order to accurately convey it in the target language. Due to the volume of content that's passed through my brain, the structures underlying user education content are ingrained in the way I think and have benefited me in my own personal learning strategies.
              </p>
              {/* <p className="about__content-text"> Research shows that 80% of objectives can be achieved with just 20% of the applied skills of a specialist in any given field &mdash; the '80/20 rule'. The skills listed below are rated based on whether an actual objective was achieved with the skill. If an objective was achieved, I gave it a four, based on the 80/20 rule. Again, that's not to say I consider myself an expert &mdash; it means there's a good chance I can get the job done either with the skills already have or by building on those skills to acquire task-oriented additional proficiency.</p> */}
              <p className="about__content-text">
                As a translation memory technologist, my objective was to improve consistency, identify and reduce repetition, and maximize productivity. That meant applying cost-benefit analyses to large datasets on a regular basis over the course of several years. It's in my nature to analyze systems, eliminate waste, and simplify complex processes &mdash; distilling out the essential from the extraneous is as natural to me as breathing. 
              </p>

          <div className="about__content-done">
            <h2 className="about__content-heading">
              What has this person done?
            </h2>
            <p className="about__content-text">
              My professional path has two branches. The first is a 15-year career in the localization of US English user education content &mdash; including software, documentation, graphics, programming code, and translation memory technology &mdash; for Microsoft Corporation and other software giants in Seattle's tech community.
            </p>
            <p className="about__content-text">But localization is more <span className="emphasis">re-creative</span> than creative in that it focuses on reproducing source language content in another language rather than producing original content. By 2003 I was ready for a career redirection. </p>
            <p className="about__content-text">In 2005 I relocated from Seattle to the East Coast due to family concerns. My objective was to hone the creative skills that my localization background didn't provide. For examples of how I've applied those skills, see the <Link to='/'>What</Link> page. </p>
          </div>

          
        

        </div>
      </div>
    </div>

    </>
  );
};

export default About;