import React from 'react'
import projects from '../json/projects.json'
import ProjectContent from './ProjectContent'

const Projects = () => {
  return (
    <>
      <div className='page__content'>
        <div className='content-banner'>
          <div className='content-banner-content'>
            <ul className='content-banner-content-list'>
              <li>Creations </li>
              <li>
                <span className='lg-bullet'></span>
              </li>
              <li>Projects</li>
              <li>
                {' '}
                <span className='lg-bullet'></span>
              </li>
              <li>Pursuits</li>
            </ul>
          </div>
        </div>
        <ProjectContent projects={projects} />
      </div>
    </>
  )
}

export default Projects
