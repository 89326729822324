import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.scss';

/* !VA  

120421
DONE: Fix all the remaining media queries
DONE: Fix nav block in NavbarMenuItems.js
DONE: Add Creations & Projects bar to Creations page
DONE: Enable the Faves somewhere
DONE: Fix the footer width
DONE: Add the How I Think section
DONE: Fix the Faves project entry
DONE: Swap person and projects

120921
TODO: 



 */

ReactDOM.render(<App />, document.querySelector('#root'));