import React from 'react';
import HeaderImages from './HeaderImages';

const TopBanner = () => {
  return (
    <div className="top-banner full">
      <div className="top-banner-content">
        <div className="top-banner-left">
          <div className="top-banner-title">
            <h1>Van Albert</h1>
          </div>
          <div className="top-banner-subtitle">
            <h2>All Things Digital</h2>
          </div>
          <div className="top-banner-tagline">
            <ul className="top-banner-tagline-list">
              <li>Content <span className="lg-bullet"></span></li>
              <li>Code <span className="lg-bullet"></span></li>
              <li>Creation</li>
            </ul>
          </div>
          <div className="top-banner-slogan">
            <h2>'100% Remote Productivity'</h2>
          </div>
        </div>
        <div className="top-banner-right">
          <HeaderImages />
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
