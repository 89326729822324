import React from 'react';
import WorkstationBuild from '../assets/workstation-image.jpg';
import WorkstationDevices from '../assets/workstation-devices.jpg';


const WorkstationContent = () => {
  return (
    <>
    <div className="page__content page__content-about">
      <div className="segment about">
        <div className="about__content">

          <div className="about__content-work">
            <h2 className="about__content-heading">
              How does this person work?
            </h2>
            <p className="about__content-text">
              My workstation is where I spend most of my time, so it has to be efficient, versatile and ergonomic. Since none of the available off-the-shelf products met my needs, I decided to design and build it myself.
            </p>
            <h3 className="about__content-subheading">
              Workstation Build
            </h3>
            <p className="about__content-text">
              The most important aspects of my workstation design are <span className="semibold">work surface</span> and <span className="semibold">ergonomics</span>. 
            </p>
            <p className="about__content-text">
              <span className="semibold">Work surface</span> &mdash; A good workstation has enough surface area to accomodate in-use items without stacking them. Once the first pile forms, clutter and chaos are right around the corner. 
            </p>
            <p className="about__content-text">
              <span className="semibold">Ergonomics</span> &mdash; A good workstation allows the user to shift body position to reduce muscular tension and prevent repetitive-stress injuries. 
            </p>
            <div className="about__content-image-left">
              <div className="about__content-image-left-left">
                <img src={WorkstationBuild} alt="Van Albert Workstation Build" className="about__content-workstation"/>
              </div>
              <div className="about__content-image-left-right">
                {/* <h4 className="about__content-image-caption">
                  Workstation Structure
                </h4> */}
                <ul className="about__content-image-description-list">
                  <li>
                    Frame: Varidesk-style electric sit-stand.
                  </li>
                  <li>Desktop: 60 inch X 36 X 3/4 inch cherry plywood desktop. Several two-inch holes provide underside access for cable management and device mounts. Cutout for the user's midsection shortens the distance between user and mouse. Edges bevelled with a 3/4-inch router bit. Surface finished with polyurethane clear coat. </li>
                  <li>
                    Riser: 60 inch X 18 inch for speakers and backlighting, similarly cut with bevelled cable management holes and finish.
                  </li>
                </ul>
              </div>
            </div>
            <h3 className="about__content-subheading">
              Input Devices
            </h3>
            <p className="about__content-text">
              I've experimented with many different input devices and configurations. In my experience, sticking with a single configuration leads to musculoskeletal stress and eventually physical injury. </p>
            <div className="about__content-image-left">

              <div className="about__content-image-left-left">
                <img src={WorkstationDevices} alt="Van Albert Workstation Devices" className="about__content-workstation"/>
              </div>

              <div className="about__content-image-left-right">
              
                <ul className="about__content-image-description-list">
                  <li>
                    Kinesis Freestyle Pro Split Mechanical
                  </li>
                  <li>Logitech standard wired mouse</li>
                  <li>Kensington Orbit Trackball with Scroll Ring</li>
                  <li>Logitech N304 Numeric Keypad mounted on 3" adjustible riser</li>
                  <li>XConnection SpaceMouse Pro</li>
                </ul>
              </div>
            </div>
            <p className="about__content-text"><span className="semibold">Keyboard</span>:&nbsp;&nbsp;I noticed that users with standard keyboards tend to hunch the shoulders forward to position the hands in front of the midsection for typing, and they tend to extend the arm far out to the right beyond the keypad to move the mouse. Two years ago I switched to a Kinesis Freestyle Pro split mechanical keyboard, which promotes a more natural typing position with the elbows at the side and the arms inline with the line of vision. 
            </p>
            <p className="about__content-text"><span className="semibold">Keypad</span>:&nbsp;&nbsp;The split keyboard leaves a space between the two keyboard components. Not to waste that space, I designed and 3D-printed an adjustible keypad stand/riser that mounts in a hole in the work surface. With this, I can use the keypad without unergonmically reaching my arm out to the right.
            </p>
            <p className="about__content-text">
              <span className="semibold">Pointing devices</span>:&nbsp;&nbsp;Like most people, I tend to 'lean into' the mouse when it's positioned to the right of a standard keyboard. For me, this led to an asymmetrical alignment of left/right shoulders and arm, one of the leading causes of repetitive stress injuries. I solved this problem by alternating on the right between a Tensing vertical mouse and a Kensington Orbit trackball. The Orbit has a unique feature been a real stress-buster for me &mdash; a dedicated scroll ring activated by the ring finger. On the left, I keep an XConnection SpaceMouse Pro for 3D design, animation and anything that requires orbiting, panning or zooming objects. 
            </p>
            <h3 className="about__content-subheading">
              Displays
            </h3>
            <p className="about__content-text">
             After much experimentation, I have a 27" main display at eye level directly in front of me and two auxiliary 24" displays angled at about 30 degrees towards me. The auxiliary displays are positioned about 4 inches below the main one. I found that tilting the head to one side and <span className="emphasis">upward</span> to view a monitor at the same height as the main display was an unnatural neck position that resulted in stress and discomfort. Dropping the displays down 4 inches allows me center my view on the auxiliary displays without craning my neck upwards. Shortly after doing this, the discomfort went away. </p>
          </div>


        </div>
      </div>
    </div>

    </>
  );
};


export default WorkstationContent;