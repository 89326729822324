import React from 'react';
import './reset.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TopBanner from './components/TopBanner';
import Footer from './components/Footer';
import Continued from './components/Continued';
import Navbar from './routes/Navbar';
// import useSticky from "./hooks/useSticky.js";
// import StickyTrigger from './components/StickyTrigger';
// import Faves from './routes/Faves';
import Projects from './routes/Projects';
import About from './routes/About';
// !VA ScrollToTop ensures that Link elements drive to the top of the target page, otherwise the scroll position is inherited from the page clicked away from.
import ScrollToTop from './components/ScrollToTop';
import Workstation from './routes/Workstation';




const App = () => {
  return (
    <div className="app-container">
      <BrowserRouter>
        <ScrollToTop />
        <div className="header full">
          <TopBanner />
          <Navbar />
        </div>
        <>

          <Routes>
            <Route path='/' element={<Projects />} />
            <Route path="/how" element={<Workstation />}/>
            <Route path="/who" element={<About />}/>
          </Routes>

           {/* <Route path="/contact" component={Contact} /> */}
        </>
        <div className="continued">
          <Continued />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;