import React from 'react'

const Continued = () => {
  return (
    <div className='continued'>
      <hr />
      <h3 className='continued-heading'>
        Thanks for visiting. It's March&nbsp;12th,&nbsp;2023. The site is
        actively maintained and new content is added periodically.
      </h3>
      <hr />
    </div>
  )
}

export default Continued
